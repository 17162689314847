import * as React from "react";
import { TopBannerModel } from "../TopBannerModel.csharp";

export const TopBanner = (props: TopBannerModel) => {
    const parseStyleString = (styleString) => {
        return styleString.split(";").reduce((styleObj, styleRule) => {
            if (styleRule) {
                const [property, value] = styleRule.split(":").map((str) => str.trim());
                if (property && value) {
                    styleObj[property] = value;
                }
            }
            return styleObj;
        }, {});
    };
    return (
        <div
            className={`TopBanner ${props.theme === "custom" ? "" : "TopBannerHeader--" + props.theme}`}
            style={props.theme === "custom" ? parseStyleString(props.customThemeStyleString) : {}}
        >
            <div className="TopBanner__DisplayArea">
                <div className="TopBanner__TextArea">
                    {props.title && (
                        <p
                            className={`TopBanner__Title ${props.theme === "custom" ? "" : "TopBannerHeader__titleText--" + props.theme
                                }`}
                            style={props.theme === "custom" ? parseStyleString(props.customTitleTextColorStyleString) : {}}
                        >
                            {props.title}
                        </p>
                    )}
                    <h2 className="TopBanner__Intro">{props.intro}</h2>
                </div>
                <div className="TopBanner__LinkArea">
                    {props.shopLinkText && props.shopLinkTarget && (
                        <a
                            className={`TopBanner__Link ${props.theme === "custom" ? "" : "TopBannerHeader__button--" + props.theme
                                }`}
                            style={props.theme === "custom" ? parseStyleString(props.customButtonStyleString) : {}}
                            href={props.shopLinkTarget}
                        >
                            {props.shopLinkText}
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};
