import React from "react";
import { ICategory } from "../CategoryPage";
import { TopBannerModel } from "../../../../Partials/TopBanner/TopBannerModel.csharp";
import { TopBanner } from "../../../../Partials/TopBanner/Components/TopBanner";
import classNames from "classnames";

interface IHeader {
    name: string;
    subCategories: ICategory[];
    urlPath: string;
    ecomPageBaseUrl: string;
    ecomPageBaseText: string;
    topBanner: TopBannerModel;
}

export const Header = ({ name, subCategories, urlPath, ecomPageBaseUrl, ecomPageBaseText, topBanner }: IHeader) => {
    const parents = urlPath && urlPath.split("/").slice(0, -1);

    // Use this mock for easier testing of the themes and custom colors
    // const mock = {
    //     topBanner: {
    //         theme: "custom",
    //         customThemeBackgroundColor: "#371202",
    //         customThemeFontColor: "#E6EE5B",
    //         customTitleTextColor: "#FFFFFF",
    //         customButtonBackgroundColor: "#E6EE5B",
    //         customButtonTextColor: "#000000",
    //         title: "Artist edition",
    //         intro: "CONSTANCE TENVIK",
    //         shopLinkText: "Look",
    //         shopLinkTarget: "/edvard-munch/",
    //         showTopBanner: true,
    //         customThemeStyleString: "background:#371202; color:#E6EE5B",
    //         customTitleTextColorStyleString: "color:#FFFFFF",
    //         customButtonStyleString: "background:#E6EE5B; color:#000;",
    //     },
    // };

    return (
        <section className="Header__Container">
            <div className="Header__Background"></div>
            <div
                className={classNames("Header__Wrapper", {
                    "Header__Wrapper--no-top-banner": !topBanner.showTopBanner,
                })}
            >
                {topBanner.showTopBanner && <TopBanner {...topBanner} />}
                {!topBanner.showTopBanner && (
                    <div className="Header__TopContainer">
                        <ul className="Header__BreadCrumb">
                            <li>
                                {ecomPageBaseUrl && (
                                    <a href={ecomPageBaseUrl}>
                                        {parents.length === 0 && <span className="Header__BreadCrumb--single" />}
                                        {ecomPageBaseText}
                                    </a>
                                )}
                            </li>
                            {parents.length !== 0 &&
                                parents.map((parent, i) => {
                                    const url = parents.slice(0, i + 1).join("/");
                                    return (
                                        <li key={parent}>
                                            <a href={url}>{parent}</a>
                                        </li>
                                    );
                                })}
                        </ul>

                        <h1 className="Header__Title">{name}</h1>
                    </div>
                )}
                {subCategories && subCategories.length > 0 && (
                    <div className="Header__SubCategories">
                        <ul className="Header__SubCategories__container">
                            {subCategories.map((child) => (
                                <li key={child.id}>
                                    <a href={child.urlPath}>{child.name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </section>
    );
};
