import React, { useState, useEffect } from "react";
import { CategoryProps } from "./CategoryProps.csharp";
import { Header } from "./Header/Header";
import { ProductList } from "./ProductList/ProductList";
import { NavigationEcom } from "../../../Partials/NavigationEcom/Components/NavigationEcom";
import { IProduct } from "../Product/ProductPage";
import { CategoryList } from "../Shared/CategoryList";
import GTMViewListItemData from "../Shared/GTM/GTMViewListItemData";

export interface ICategory {
    id: number;
    name: string;
    products: IProduct[];
    subCategories: ICategory[];
    urlPath: string;
}

export const CategoryPage = (props: CategoryProps) => {
    const [productList, setProductList] = useState<IProduct[]>([]);
    const [cartId, setCartId] = useState(props.navigationEcomProps.cart.cartId);
    const [cart, setCart] = useState([]);
    const [cartIsOpen, setCartIsOpen] = useState(false);
    const [tickets, setTickets] = useState([]);

    const [numberOfProducts, setNumberOfProducts] = useState(0);

    useEffect(() => {
        props.category && setProductList(props.category.products);
    }, []);
    const changeSort = (sortValue: string) => {
        if (sortValue === "highest")
            setProductList((prev) => [
                ...prev.sort(
                    (a, b) =>
                        parseInt(b.price.originalPrice.replace(",-", "")) -
                        parseInt(a.price.originalPrice.replace(",-", ""))
                ),
            ]);
        else if (sortValue === "lowest")
            setProductList((prev) => [
                ...prev.sort(
                    (a, b) =>
                        parseInt(a.price.originalPrice.replace(",-", "")) -
                        parseInt(b.price.originalPrice.replace(",-", ""))
                ),
            ]);
        else if (sortValue === "newest")
            setProductList((prev) => [
                ...prev.sort((a, b) => new Date(b.updateDate).getTime() - new Date(a.updateDate).getTime()),
            ]);
    };

    useEffect(() => {
        // @ts-ignore
        const dataLayer = window.dataLayer || [];
        dataLayer.push(GTMViewListItemData(props.title, props.categoryUrlPath, props.category.products));
    }, []);

    return (
        <>
            <NavigationEcom
                productCount={numberOfProducts}
                products={cart}
                props={props.navigationEcomProps}
                cartIsOpen={cartIsOpen}
                setCartIsOpen={setCartIsOpen}
                menuItems={props.navigationEcomProps.model.categoryUrlWithNameList}
                setCartId={setCartId}
                cartId={cartId}
                setCart={setCart}
                setNumberOfProducts={setNumberOfProducts}
                setTickets={setTickets}
                tickets={tickets}
            ></NavigationEcom>
            <main className="maincontent" id="maincontent">
                <Header
                    name={props.category.name}
                    subCategories={props.category.subCategories}
                    urlPath={props.categoryUrlPath}
                    ecomPageBaseUrl={props.ecomBasePageUrl}
                    ecomPageBaseText={props.ecomBasePageText}
                    topBanner={props.topBannerModel}
                />
                <div className="CategoryPage">
                    <div className="CategoryPage__Container">
                        <ProductList
                            changeSort={changeSort}
                            editorialProps={{
                                currencyFormat: props.currencyFormat,
                                sortingOptions: props.sortingOptions,
                                productPageLinkUrl: props.productPageLinkUrl,
                                productList: productList,
                                sortingButtonText: props.sortingButtonText,
                                showProductsText: props.showProductsText,
                            }}
                        />
                        {props.category && (
                            <div className="CategoryPage__Section__container">
                                <div className="CategoryInformation__wrapper">
                                    {props.category?.name && <h2>{props.category.name}</h2>}
                                    {props.category.description && (
                                        <div
                                            className="CategoryInformation__paragraph"
                                            dangerouslySetInnerHTML={{ __html: props.category?.description }}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="CategoryPage__Section__container">
                            {props.navigationEcomProps.model.categoryUrlWithNameList &&
                                props.navigationEcomProps.model.categoryUrlWithNameList.length > 0 && (
                                    <CategoryList
                                        categoryUrlList={props.navigationEcomProps.model.categoryUrlWithNameList}
                                    />
                                )}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};
