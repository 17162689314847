import React, { useEffect, useState } from "react";
import { ColorSelection } from "./ColorSelection/ColorSelection";
import { ProductProps } from "./ProductProps.csharp";
import { ProductImages } from "./ProductImages/ProductImages";
import { Breadcrumbs } from "../Shared/Breadcrumbs";
import { EcomAccordion } from "../Shared/EcomAccordion";
import { Select } from "../Shared/Select";
import { CarouselBlock } from "../Shared/CarouselBlock/CarouselBlock";
import { NavigationEcom } from "../../../Partials/NavigationEcom/Components/NavigationEcom";
import { IColor } from "./ColorSelection/ColorSelection";
import { AccordionItemProps } from "../Shared/EcomAccordion";
import { CategoryList } from "../Shared/CategoryList";
import { Loader } from "../Shared/Loader";
import { ErrorMessage } from "../Shared/ErrorMessage";
import { graphQlRequestBodyString } from "./graphQlRequestBodyString";
import classNames from "classnames";
import GTMViewItemData from "../Shared/GTM/GTMViewItemData";
import GTMFormatPrice from "../Shared/GTM/GTMFormatPrice";
import { ProductCard } from "../Shared/ProductCard";
export interface ISize {
    name: string;
    sku: string;
    price: number;
    detailedPrice: IDetailedPrice;
}

export interface IDetailedPrice {
    discountPrice: string;
    format: string;
    originalPrice: string;
    vatPrice: string;
}

export interface IProduct extends ProductProps {
    price: IDetailedPrice;
    images: { url: string; altText: string }[];
    sizes: ISize[];
    colors: IColor[];
    productName: string;
    updateDate: string;
    urlKey: string;
    details: { label: string; text: string }[];
    accordionItems: AccordionItemProps[];
}

export const ProductPage = (props: ProductProps) => {
    const sizes: ISize[] = props.sizes;
    const images = props.images;
    const [sizeOriginalPrice, setSizeOriginalPrice] = useState<string>(
        props.sizes && props.sizes.length !== 0 && props.sizes[0].detailedPrice.originalPrice
    );
    const [sizeDiscountPrice, setSizeDiscountPrice] = useState<string>(
        props.sizes && props.sizes.length !== 0 && props.sizes[0].detailedPrice.vatPrice
    );
    const [sizeName, setSizeName] = useState<string>(props.sizes && props.sizes.length !== 0 && props.sizes[0].name);

    const changeSize = (value: string) => {
        const price = sizes.find((size) => size.name === value).detailedPrice.originalPrice;
        const discountPrice = sizes.find((size) => size.name === value).detailedPrice.vatPrice;
        const name = sizes.find((size) => size.name === value).name;

        setSizeOriginalPrice(price);
        setSizeDiscountPrice(discountPrice);
        setSizeName(name);
    };

    const [cartId, setCartId] = useState(props.navigationEcomProps.cart.cartId);

    const [cart, setCart] = useState([]);

    const [tickets, setTickets] = useState([]);

    const [cartIsOpen, setCartIsOpen] = useState(false);

    const [numberOfProducts, setNumberOfProducts] = useState(0);

    const [isLoading, setIsLoading] = useState(false);

    const [showError, setShowError] = useState(false);

    const [giftText, setGiftText] = useState("");

    const [isSafari, setIsSafari] = useState(false);

    const SchemaData = {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: props.title,
        description: props.description,
        image: props.images[0].url,
        brand: props.brand,
        offers: {
            "@type": "Offer",
            priceCurrency: "NOK",
            price: GTMFormatPrice(props.price.vatPrice, "string"),
            availability: "InStock",
        },
    };

    const handleInputChange = (event) => {
        setGiftText(event.target.value);
    };
    

    const clearLoader = (timeout) => {
        clearTimeout(timeout);
        setIsLoading(false);
    };

    const changePriceFormat = (price) => {
        price = price.replace(",-", "");
        price = price.replace(",", ".");

        return price;
    };

    const isVoucherProduct = props.productType === "Voucher";

    const addToCart = async () => {
        const guestHeadersGraphQl = {
            "content-type": "application/json",
            "store": props.navigationEcomProps.languageSwitcherViewModel.currentLanguage
        };
        const memberHeadersGraphQl = {
            "content-type": "application/json",
            Authorization: `Bearer ${props.navigationEcomProps.cart.accessToken}`,
            "store": props.navigationEcomProps.languageSwitcherViewModel.currentLanguage
        };

        const guestHeadersRest = {
            "content-type": "application/json",
            
        };

        const memberHeadersRest = {
            "content-type": "application/json",
            Authorization: `Bearer ${props.navigationEcomProps.cart.accessToken}`,
            
        };

        const isMember = props.navigationEcomProps.cart.accessToken ? true : false;

        const giftMemberEndpoint = props.navigationEcomProps.cart.endpointMemberCartGift;

        const giftItem = {
            product_id: props.productSku,
            value: "-1",
            custom_value: changePriceFormat(props.price.originalPrice),
            recipient_name: "MUNCH",
            recipient_email: "no-reply@munchmuseet.no",
            message: giftText,
            qty: 1,
        };

        const getVariants = isVoucherProduct
            ? {
                  item: giftItem,
              }
            : {
                  query: `{
                products(filter: {sku: {eq: "${props.productSku}"}}) {
                  items {
                    sku
                    ... on ConfigurableProduct {
                      variants {
                        attributes {
                          uid
                          label
                          code
                        }
                      }
                    }
                  }
                }
              }`,
                  variables: {},
              };

        const variantOptions = {
            method: "POST",
            headers: props.navigationEcomProps.cart.accessToken ? memberHeadersGraphQl : guestHeadersGraphQl,
            body: JSON.stringify(getVariants),
        };

        const variantsResponse = await fetch(props.navigationEcomProps.graphQlEndpoint, variantOptions);
        const variantsData = await variantsResponse.json();

        const timeout = setTimeout(() => setIsLoading(true), 500);
        const currentSelectedVariantId =
            variantsData.data &&
            variantsData.data.products &&
            variantsData.data.products.items[0] &&
            variantsData.data.products.items[0].variants
                ? variantsData.data.products.items[0].variants?.find((item) => item.attributes[0].label === sizeName)
                      .attributes[0].uid
                : undefined;

        let graphqlQuery = isVoucherProduct
            ? {
                  item: giftItem,
              }
            : {
                  operationName: "addProductsToCart",
                  query: `mutation {
                addProductsToCart(
                    cartId: "${cartId}"
                    cartItems: [
                        {
                            quantity: 1
                            selected_options: ["${currentSelectedVariantId}"]
                            sku: "${props.productSku}"
                        }
                    ]
                ) {
                    cart {
                        ${graphQlRequestBodyString}
                    }
                    user_errors {
                        code
                        message
                    }
                }
                }`,
                  variables: {},
              };

        const optionsGraphQl = {
            method: "POST",
            headers: isMember ? memberHeadersGraphQl : guestHeadersGraphQl,
            body: JSON.stringify(graphqlQuery),
        };

        const optionsRest = {
            method: "POST",
            headers: isMember ? memberHeadersRest : guestHeadersRest,
            body: JSON.stringify(graphqlQuery),
        };

        const response = await fetch(
            isVoucherProduct && !isMember
                ? `${props.navigationEcomProps.cart.endpointGuestCartNoId}/${cartId}/voucher-item`
                : isVoucherProduct && isMember
                ? giftMemberEndpoint
                : props.navigationEcomProps.graphQlEndpoint,
            isVoucherProduct ? optionsRest : optionsGraphQl
        );
        const data = await response.json();

        if (
            (data.data &&
                data.data.addProductsToCart &&
                data.data.addProductsToCart.user_errors &&
                data.data.addProductsToCart.user_errors.length !== 0 &&
                data.data.addProductsToCart.user_errors[0].code === "INSUFFICIENT_STOCK") ||
            !response.ok
        ) {
            setShowError(true);
            clearLoader(timeout);
        } else {
            const getOptions = {
                method: "GET",
                headers: props.navigationEcomProps.cart.accessToken ? memberHeadersRest : guestHeadersRest,
            };

            const getResponse = await fetch(
                `${props.navigationEcomProps.cart.endpointGuestCartNoId}/${cartId}/items`,
                getOptions
            );

            const getData = await getResponse.json();

            const ticketsWithoutProducts = getData.filter(
                (item) =>
                    item.product_type === "ticket" ||
                    item.product_type == "membership_renew" ||
                    item.product_type == "membership" ||
                    item.product_type == "voucher"
            );

            setTickets(ticketsWithoutProducts);

            const productsWithoutTickets = data.data
                ? data.data.addProductsToCart.cart.items.filter((item) => item !== null)
                : cart;

            setNumberOfProducts && setNumberOfProducts(getData.reduce((partialSum, a) => partialSum + a.qty, 0));
            setCart(productsWithoutTickets);
            setCartIsOpen(true);
            setShowError(false);
            clearLoader(timeout);

            // @ts-ignore
            window.dataLayer = window.dataLayer || [];
            // @ts-ignore
            window.dataLayer.push({ ecommerce: null });
            // @ts-ignore
            window.dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                    currency: "NOK",
                    value: GTMFormatPrice(props.price.vatPrice, "string"),
                    items: [
                        {
                            item_id: props.productSku,
                            item_name: props.title,
                            item_brand: props.brand,
                            item_variant: sizeName,
                            price: GTMFormatPrice(props.price.vatPrice, "string"),
                            quantity: 1
                        }
                    ]
                }
            });
        }
    };

    useEffect(() => {
        const userAgent = navigator.userAgent;
        const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
        setIsSafari(isSafari);

        // @ts-ignore
        const dataLayer = window.dataLayer || [];
        dataLayer.push(GTMViewItemData(props));
    }, []);

    return (
        <div className="ProductPage">
            <NavigationEcom
                productCount={numberOfProducts}
                products={cart}
                props={props.navigationEcomProps}
                cartIsOpen={cartIsOpen}
                setCartIsOpen={setCartIsOpen}
                menuItems={props.navigationEcomProps.model.categoryUrlWithNameList}
                setCartId={setCartId}
                cartId={cartId}
                setCart={setCart}
                setNumberOfProducts={setNumberOfProducts}
                setTickets={setTickets}
                tickets={tickets}
            ></NavigationEcom>
            <script type="application/ld+json">{JSON.stringify(SchemaData)}</script>
            <main className="maincontent" id="maincontent">
                <div className="ProductPage__content">
                    <div className="ProductPage__Information__container">
                        <div
                            className={classNames("ProductPage__ProductInformation", {
                                "ProductPage__ProductInformation--sticky": images.length > 1,
                            })}
                        >
                            <p className="ProductInformation__brand">{props.brand}</p>
                            <h1 className="ProductInformation__title">{props.title}</h1>
                            <div className="ProductInformation__price">
                                {props.price.originalPrice &&
                                    props.price.vatPrice &&
                                    props.price.vatPrice !== props.price.originalPrice && (
                                        <div className="ProductInformation__price--discount">
                                            {props.price.format.replace(
                                                "price",
                                                props.sizes.length === 0 ? props.price.vatPrice : sizeDiscountPrice
                                            )}
                                        </div>
                                    )}
                                <div>
                                    {props.price.originalPrice &&
                                    props.price.vatPrice &&
                                    props.price.vatPrice !== props.price.originalPrice ? (
                                        <s>
                                            {props.price.format.replace(
                                                "price",
                                                props.sizes.length === 0 ? props.price.originalPrice : sizeOriginalPrice
                                            )}
                                        </s>
                                    ) : (
                                        props.price.format.replace(
                                            "price",
                                            props.sizes.length === 0 ? props.price.originalPrice : sizeOriginalPrice
                                        )
                                    )}
                                </div>
                            </div>
                            {props.colors && props.colors.length !== 0 && props.colorText && (
                                <ColorSelection colorText={props.colorText} colors={props.colors} />
                            )}
                            {sizes && sizes.length > 0 && (
                                <Select
                                    values={sizes}
                                    onChange={changeSize}
                                    label={props.sizeText}
                                    cn={"ProductInformation__size"}
                                />
                            )}

                            {props.messageToRecipientLabel && props.productType && props.productType === "Voucher" && (
                                <div className="ProductInformation__gift-text">
                                    <label className="ProductInformation__textarea-label" htmlFor="giftText">
                                        {props.messageToRecipientLabel}
                                    </label>
                                    <textarea
                                        id="giftText"
                                        placeholder={props.messageToRecipientLabelPlaceholder}
                                        className="ProductInformation__textarea"
                                        value={giftText}
                                        onChange={handleInputChange}
                                    ></textarea>
                                </div>
                            )}

                            <button
                                onClick={addToCart}
                                className="button--primary button--forward button--red ProductInformation__button"
                            >
                                {props.addToCartButtonText}
                                {isLoading && <Loader isSmall isWhite />}
                            </button>

                            {/*Add error message from backend*/}
                            {showError && <ErrorMessage text={"Her gikk det galt"} />}
                            <ul className="ProductInformation__usps">
                                {props.usps.map((usp) => (
                                    <li key={usp} className="usps__item">
                                        <p>{usp}</p>
                                    </li>
                                ))}
                            </ul>

                            <div className="ProductInformation__description">
                                <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
                            </div>
                            {props.details && props.details.length !== 0 && (
                                <div className="ProductInformation__detail">
                                    <h2 className="detail__header">{props.detailsText}</h2>
                                    <div className="detail__list">
                                        {props.details.map((detail) => (
                                            <div
                                                key={detail.text}
                                                dangerouslySetInnerHTML={{ __html: detail.text }}
                                            ></div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {props.accordionItems && (
                                <EcomAccordion
                                    accordionTitle={props.accordionTitle}
                                    accordionList={props.accordionItems}
                                ></EcomAccordion>
                            )}
                        </div>
                        {props.images && props.images.length !== 0 && (
                            <div
                                className={classNames("ProductPage__Images", {
                                    "ProductPage__Images--sticky": images.length === 1,
                                    "ProductPage__Images--is-safari": isSafari,
                                })}
                            >
                                <Breadcrumbs items={props.breadcrumbs} />
                                <ProductImages images={props.images} />
                            </div>
                        )}
                    </div>
                </div>
                {props.upsaleProducts && props.upsaleProducts.length > 0 && (
                    <CarouselBlock
                        title={props.upsellProductsTitle}
                        bgColor="green"
                        previousSlideAriaLabel={props.previousSlideAriaLabel}
                        nextSlideAriaLabel={props.nextSlideAriaLabel}
                        displayData={props.upsaleProducts.map((product) => ({
                            key: product.productName,
                            data: (
                                <ProductCard
                                    product={{
                                        url: product.url,
                                        imageUrl: product.image.url,
                                        imageAlt: product.image.altText,
                                        title: product.name,
                                        brand: product.brand,
                                        originalPrice: product.price.originalPrice,
                                        vatPrice: product.price.vatPrice,
                                    }}
                                    currencyFormat={props.price.format}
                                />
                            ),
                        }))}
                    />
                )}

                {props.relatedProducts && props.relatedProducts.length > 0 && (
                    <CarouselBlock
                        title={props.relatedProductsTitle}
                        bgColor="blue"
                        previousSlideAriaLabel={props.previousSlideAriaLabel}
                        nextSlideAriaLabel={props.nextSlideAriaLabel}
                        displayData={props.relatedProducts.map((product) => ({
                            key: product.productName,
                            data: (
                                <ProductCard
                                    product={{
                                        url: product.url,
                                        imageUrl: product.image.url,
                                        imageAlt: product.image.altText,
                                        title: product.name,
                                        brand: product.brand,
                                        originalPrice: product.price.originalPrice,
                                        vatPrice: product.price.vatPrice,
                                    }}
                                    currencyFormat={props.price.format}
                                />
                            ),
                        }))}
                    />
                )}
            </main>
            {props.navigationEcomProps.model.categoryUrlWithNameList && (
                <CategoryList
                    categoryUrlList={props.navigationEcomProps.model.categoryUrlWithNameList}
                    ariaLabelText={props.navigationEcomProps.model.categoriesAriaLabel}
                />
            )}
        </div>
    );
};
